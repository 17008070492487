import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  DOCUMENT
} from '@angular/core';
import {Event} from '../../dto';
import {TranslateModule} from '@ngx-translate/core';
import {ScpService, StorageService} from '@scpc/modules/common/services';
import {ButtonComponent} from '@scpc/modules/common/components/button/button.component';
import {Customer} from '@scpc/dto/customer';
import {SportsService} from '@scpc/modules/sports/services/sports.service';
import {Subject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormatMoneyPipe} from '@scpc/modules/common/pipes/format-money.pipe';
import {Money} from '@scpc/dto';
import {finalize, takeUntil} from 'rxjs/operators';

import '@material/web/progress/circular-progress.js';

type State =
  'LOADING'
  | 'SIGN_IN'
  | 'KYC'
  | 'KYC_IN_PROGRESS'
  | 'LOW_BALANCE'
  | 'GEO_BLOCK'
  | 'SERVICE_UNAVAILABLE'
  | 'WATCH';

@Component({
  selector: 'scp-sports-event-video',
  templateUrl: './event-video.component.html',
  styleUrls: ['./event-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    ButtonComponent,
    FormatMoneyPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventVideoComponent implements OnChanges {

  @Input()
  public event: Event;

  @Input()
  public customer: Customer;

  @Input()
  public isAuthorized: boolean;

  @Output()
  public signIn: EventEmitter<void> = new EventEmitter<void>();

  protected state: State = 'LOADING';
  protected minBalance: Money;
  protected url: string;

  private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private subscription: Subject<void>;
  private sportsService: SportsService = inject(SportsService);
  private scpService: ScpService = inject(ScpService);
  private storageService: StorageService = inject(StorageService);
  private document: Document = inject(DOCUMENT);
  private elementRef: ElementRef = inject(ElementRef);

  public ngOnChanges(changes: SimpleChanges): void {
    if ('customer' in changes || 'isAuthorized' in changes) {
      this.updateState();
    }
  }

  private updateState(): void {
    this.subscription?.next();
    this.subscription?.complete();

    if (!this.isAuthorized) {
      this.setState('SIGN_IN');
    } else if (this.customer.status === 'INACTIVE' && this.customer.ekyc?.status !== 'CONFIRMED' && this.customer.kyc?.status !== 'CONFIRMED') {
      if (this.customer.kyc?.status === 'IN_PROGRESS') {
        this.setState('KYC_IN_PROGRESS');
      } else if ((this.customer.forbidden?.includes('kyc') || this.customer.forbidden?.includes('kyc-nr')) && this.customer.forbidden?.includes('ekyc')) {
        this.setState('SERVICE_UNAVAILABLE');
      } else {
        this.setState('KYC');
      }
    } else if (this.state !== 'WATCH' && this.customer.status === 'ACTIVE') {
      this.subscription = new Subject<void>();
      this.sportsService.getStream(this.event.id)
        .pipe(takeUntilDestroyed(this.destroyRef), takeUntil(this.subscription))
        .subscribe({
          next: (data: { url: string }): void => this.setState('WATCH', data.url),
          error: (response: HttpErrorResponse): void => {
            if (response.status === 401) {
              this.setState('SIGN_IN');
            } else if (response.status === 400) {
              switch (response.error.error?.code) {
                case 1:
                  this.minBalance = {currency: 'ZAR', value: response.error.error.amount};
                  return this.setState('LOW_BALANCE');
                case 2:
                  return this.setState('GEO_BLOCK');
                case 4:
                case 5:
                  this.scpService.getCustomer().pipe(
                    takeUntilDestroyed(this.destroyRef),
                    takeUntil(this.subscription),
                    finalize(() => this.setState(response.error.error?.code === 4 ? 'KYC' : 'KYC_IN_PROGRESS')),
                  ).subscribe((customer: Customer) => this.storageService.setCustomer(customer));
                  return;
                default:
                  this.setState('SERVICE_UNAVAILABLE');
                  return;
              }
            } else {
              this.setState('SERVICE_UNAVAILABLE');
            }
          },
        });
    } else {
      this.setState('SERVICE_UNAVAILABLE');
    }
  }

  private setState(state: State, url?: string): void {
    const old: string = this.state;
    if (old !== state) {
      this.state = state;
      this.url = url;
      this.changeDetectorRef.detectChanges();
      if (this.state === 'WATCH') {
        const link: HTMLLinkElement = this.document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://avplayer-cdn.akamaized.net/dist/latest/styles.css';
        link.onload = (): void => {
          const script: HTMLScriptElement = this.document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'https://avplayer-cdn.akamaized.net/dist/latest/avvpl-hls-player.js';
          script.onload = (): void => {
            new global.avvpl.setupPlayer({
              id: 'playercontainer',
              trackLiveEdge: true,
            });
          };
          this.elementRef.nativeElement.appendChild(script);
        };
        this.elementRef.nativeElement.appendChild(link);
      }
    }
  }

}
