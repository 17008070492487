import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Inject,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  DOCUMENT
} from '@angular/core';
import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { Event, EventMarket, EventMarketGroup, EventMatchProperties, EventOutcome } from '../../dto';
import { EventCardComponent } from '@scpc/modules/sports/components/event-card/event-card.component';
import { TournamentIconComponent } from '@scpc/modules/sports/components/tournament-icon/tournament-icon.component';
import {
  EventRowSkeletonComponent,
} from '@scpc/modules/sports/components/event-row-skeleton/event-row-skeleton.component';
import { MatRipple } from '@angular/material/core';
import { OddsPipe } from '@scpc/modules/common/pipes/odds.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { Swiper } from 'swiper';
import { FreeMode } from 'swiper/modules';
import { MatIconButton } from '@angular/material/button';
import { catchError } from '@scpc/utils/dom.utils';
import { NoResultsComponent } from '@scpc/modules/common/components/no-results/no-results.component';
import { SportsService } from '@scpc/modules/sports/services/sports.service';
import { SortedSet } from 'sweet-collections';
import { formatCorrectScoreOutcomes, mergeMarkets, reduceMarkets } from '@scpc/modules/sports/utils/markets';
import { isEventEnded } from '@scpc/modules/sports/utils/event';
import { SportsCartService } from '@scpc/modules/cart/services/sports-cart.service';
import { SportCartSelectionId } from '@scpc/modules/cart/model';
import { CommonCartService } from '@scpc/modules/cart/services/common-cart.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { VisibilityDirective } from '@scpc/modules/common/directives/visibility.directive';
import { GoogleTagManagerService } from '@scpc/modules/common/services/analytics/google-tag-manager.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Segment, SegmentedComponent } from '@scpc/modules/common/components/segmented/segmented.component';
import { EventTrackerComponent } from '@scpc/modules/sports/components/event-tracker/event-tracker.component';
import { AuthenticationService, StorageService } from '@scpc/modules/common/services';
import { Router } from '@angular/router';
import { Customer } from '@scpc/dto/customer';
import { EventVideoComponent } from '@scpc/modules/sports/components/event-video/event-video.component';

@Component({
  selector: 'scp-sports-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    MatRipple,
    MatIconButton,
    EventCardComponent,
    TournamentIconComponent,
    EventRowSkeletonComponent,
    OddsPipe,
    NoResultsComponent,
    VisibilityDirective,
    CdkAccordion,
    CdkAccordionItem,
    SegmentedComponent,
    AsyncPipe,
    EventVideoComponent,
  ],
})
export class EventComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

  @Input()
  public event: Event;

  @Output()
  public back: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('spacer', { read: ElementRef, static: true })
  private spacer: ElementRef<HTMLElement>;

  @ViewChild('trackerRef', { read: ViewContainerRef, static: false })
  private trackerRef: ViewContainerRef;

  @ViewChild('statisticsRef', { read: ViewContainerRef, static: false })
  private statisticsRef: ViewContainerRef;

  protected isSticky: boolean;
  protected ended: boolean;
  protected markets: Map<string, { markets: EventMarket[], disabled: boolean, expanded: boolean }> = new Map();
  protected allMarkets: Map<string, EventMarket> = new Map();
  protected groups: EventMarketGroup[] = [];
  protected currentGroup: EventMarketGroup;
  protected isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));
  protected reachBeginning: boolean;
  protected reachEnd: boolean;
  protected initialLoading: boolean = true;
  protected isAllLoaded: boolean;
  protected isAllLoading: boolean;
  protected scroll: HTMLDivElement;
  protected isUp: boolean;
  protected scrollTop: number;
  protected swiper: Swiper;
  protected matchProperties: EventMatchProperties;
  protected selected: Set<string> = new Set<string>();
  protected creating: Set<string> = new Set<string>();
  protected deleting: Set<string> = new Set<string>();
  protected state: Map<string, boolean> = new Map<string, boolean>();

  protected segments: Segment<string>[] = [];
  protected segment: Segment<string> = null;
  protected isAuthorized: boolean;
  protected customer: Customer;

  private source: string = 'Event page';
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private readonly zone: NgZone,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly sportsService: SportsService,
              private readonly commonCartService: CommonCartService,
              private readonly sportsCartService: SportsCartService,
              private readonly googleTagManagerService: GoogleTagManagerService,
              protected readonly authenticationService: AuthenticationService,
              protected readonly storageService: StorageService,
              protected readonly router: Router,
              @Inject(DOCUMENT) private readonly document: Document) {
    this.authenticationService.authorization
      .pipe(takeUntilDestroyed())
      .subscribe((isAuthorized: boolean) => this.updateAuthState(isAuthorized));
    this.authenticationService.isAuthorized()
      .pipe(takeUntilDestroyed())
      .subscribe((isAuthorized: boolean) => this.updateAuthState(isAuthorized));
  }

  public ngOnInit(): void {
    this.scroll = this.document.getElementsByClassName('scp-scrollbar-content')[0] as HTMLDivElement;
    /* istanbul ignore if */
    if (this.scroll) {
      fromEvent(this.scroll, 'scroll')
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          debounceTime(15),
          map((e): number => (e.target as Element).scrollTop),
          distinctUntilChanged(),
        )
        .subscribe((scrollTop: number): void => {
          const isUp: boolean = scrollTop < this.scrollTop;
          this.scrollTop = scrollTop;
          if (this.isUp !== isUp) {
            this.isUp = isUp;
            this.update();
          }
        });
    }
  }

  public ngAfterContentInit(): void {
    this.zone.runOutsideAngular((): void => {
      this.swiper = new Swiper('.swiper-markets-groups', {
        init: this.isBrowser,
        freeMode: {
          enabled: true,
          momentum: true,
        },
        modules: [FreeMode],
        initialSlide: this.groups.findIndex((group: EventMarketGroup) => group.id === this.currentGroup.id) || /* istanbul ignore next */ 0,
        touchRatio: 1.5,
        speed: 400,
        resistance: true,
        resistanceRatio: 0.75,
        slidesPerView: 'auto',
        watchSlidesProgress: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: false,
        on: {
          afterInit: (swiper: Swiper) => this.updateSlider(swiper),
          reachBeginning: /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
          reachEnd:  /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
          resize:  /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
          setTranslate:  /* istanbul ignore next */ (swiper: Swiper, translate: number): void => {
            if (!Number.isNaN(translate)) {
              this.updateSlider(swiper);
            }
          },
        },
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    /* istanbul ignore next */
    if ('event' in changes && !changes.event.currentValue) {
      this.markets.clear();
      this.allMarkets.clear();
      this.groups = [];
      this.currentGroup = null;
      this.initialLoading = true;
      this.segment = null;
    }
    if ('event' in changes && changes.event.currentValue) {
      const first = !changes.event.previousValue;
      if (first) {
        this.googleTagManagerService.viewSportItem(this.event, this.source);
      }
      this.matchProperties = Object.entries(this.event.matchProperties || {});
      this.updateEvent(first, true);
      this.initialLoading = false;
      if (first) {
        this.sportsCartService.items()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((ids: SportCartSelectionId[]): void => {
            this.selected = new Set<string>(ids.filter((id: SportCartSelectionId): boolean => id.eventId === this.event.id)
              .map((id: SportCartSelectionId): string => id.marketUniqId + '#' + id.outcomeId));
            this.changeDetectorRef.markForCheck();
          });
        this.sportsCartService.deletingItems()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((ids: SportCartSelectionId[]): void => {
            const items: SportCartSelectionId[] = [];
            for (const id of ids) {
              if (id.eventId === this.event.id) {
                items.push(id);
              }
            }
            if (items.length !== this.deleting.size) {
              this.deleting = new Set<string>(ids.map((id: SportCartSelectionId): string => id.marketUniqId + '#' + id.outcomeId));
              this.changeDetectorRef.markForCheck();
            }
          });
        this.sportsCartService.creatingItems()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((ids: SportCartSelectionId[]): void => {
            const items: SportCartSelectionId[] = [];
            for (const id of ids) {
              if (id.eventId === this.event.id) {
                items.push(id);
              }
            }
            if (items.length !== this.creating.size) {
              this.creating = new Set<string>(ids.map((id: SportCartSelectionId): string => id.marketUniqId + '#' + id.outcomeId));
              this.changeDetectorRef.markForCheck();
            }
          });
      }
    }
  }

  public ngOnDestroy(): void {
    catchError(() => this.swiper.destroy());
  }

  public update(): void {
    this.zone.run(() => this.changeDetectorRef.markForCheck());
  }

  protected async showMarketsByGroup(group: EventMarketGroup, first: boolean, scroll: boolean, clean: boolean = true): Promise<void> {
    this.currentGroup = group;
    if (!group) {
      return;
    }
    if (clean) {
      this.state.clear();
    }
    /* istanbul ignore next */
    if (this.isSticky && this.isUp && scroll && this.scroll) {
      const element: HTMLElement = this.document.getElementsByClassName('fixed')[0] as HTMLElement;
      if (element) {
        this.scroll.scrollTo({ behavior: 'smooth', top: element.offsetTop });
      }
    }
    if (group.id === 'main' || this.isAllLoaded) {
      if (first) {
        this.markets = reduceMarkets(this.allMarkets, this.currentGroup.id, !!this.event.season, this.state, clean);
      } else {
        this.markets = await this.reduceMarketsByCurrentGroup(clean, first);
        this.update();
      }
    } else if (!this.isAllLoaded) {
      this.markets.clear();
      if (!this.isAllLoading) {
        this.isAllLoading = true;
        setTimeout((): void => {
          this.sportsService.getEvents(undefined, this.event.slug, 'all', false)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: async (events: Event[]): Promise<void> => {
                this.event = events[0];
                await this.updateEvent(false);
                this.markets = await this.reduceMarketsByCurrentGroup(clean);
                this.isAllLoaded = true;
                this.isAllLoading = false;
                this.update();
              },
              error: async (): Promise<void> => {
                this.markets = await this.reduceMarketsByCurrentGroup(clean);
                this.isAllLoading = false;
                this.update();
              },
            });
        });
      }
    }
    if (this.isBrowser && scroll) {
      this.swiper?.slideTo(this.groups.indexOf(group));
    }
  }

  protected marketsTrackBy(markets: [string, {
    markets: EventMarket[],
    disabled: boolean,
    expanded: boolean
  }]): string {
    return markets[0] + markets[1].disabled + markets[1].expanded;
  }

  protected marketTrackBy(market: EventMarket): string {
    return market.id + market.mainParam + market.name;
  }

  /* istanbul ignore next */
  protected outcomeTrackBy(outcome: EventOutcome): string {
    return outcome.id + outcome.name + outcome.odds + outcome.oldOdds +
      (this.selected?.has(outcome.combinedId) || this.creating?.has(outcome.combinedId) || this.deleting?.has(outcome.combinedId));
  }

  /* istanbul ignore next */
  protected formatCorrectScoreOutcomes(market: EventMarket): {
    a: EventOutcome[],
    b: EventOutcome[],
    c: EventOutcome[]
  } {
    return formatCorrectScoreOutcomes(market);
  }

  /* istanbul ignore next */
  protected updateState(id: string, expanded: boolean): void {
    if (this.markets.has(id)) {
      this.state.set(id, expanded);
      this.markets.get(id).expanded = expanded;
    }
  }

  protected async addToCard(market: EventMarket, outcome: EventOutcome): Promise<void> {
    if (await this.commonCartService.isBetAllowed('SPORT')) {
      outcome.oldOdds = undefined;
      await this.sportsCartService.addOrRemoveSelection(this.event, market, outcome, this.source);
    }
  }

  /* istanbul ignore next */
  protected async onOptionChange(segment: Segment<string>): Promise<void> {
    if (this.segment.value !== segment.value) {
      this.segment = segment;
      this.changeDetectorRef.detectChanges();
      this.trackerRef?.clear();
      this.statisticsRef?.clear();
      if (this.segment.value === 'EVENT') {
        this.googleTagManagerService.event('sp', 'match_result');
      } else if (this.segment.value === 'TRACKER') {
        await this.showWidget(this.segment.value, 'match_tracker');
      } else if (this.segment.value === 'STATISTICS') {
        await this.showWidget(this.segment.value, 'match_statistics');
      } else if (this.segment.value === 'VIDEO') {
        this.googleTagManagerService.event('sp', 'video');
      }
    }
  }

  /* istanbul ignore next */
  private async showWidget(type: 'TRACKER' | 'STATISTICS', gtmEvent: string): Promise<void> {
    const { EventTrackerComponent } = await import('./../event-tracker/event-tracker.component');
    const ref: ComponentRef<EventTrackerComponent> = (this.segment.value === 'STATISTICS'
        ? this.statisticsRef
        : this.trackerRef
    ).createComponent(EventTrackerComponent);
    ref.instance.showTracker(this.event.id, type, false);
    this.googleTagManagerService.event('sp', gtmEvent);
  }

  private updateSlider(swiper: Swiper): void {
    const hasOverflow: boolean = Math.round((swiper as any).virtualSize) > swiper.width;
    this.reachBeginning = !this.isBrowser || (swiper.isBeginning || !hasOverflow);
    this.reachEnd = !this.isBrowser || (swiper.isEnd || !hasOverflow);
    this.update();
  }

  private async updateEvent(first: boolean, update = false): Promise<void> {
    if (isEventEnded(this.event)) {
      this.ended = true;
      this.allMarkets = new Map<string, EventMarket>();
    } else {
      this.ended = false;
      if (first) {
        this.allMarkets = mergeMarkets(this.allMarkets, this.event.markets);
      } else {
        this.allMarkets = await this.updateMarkets(this.event.markets, first);
      }
    }
    this.updateGroups(this.event);
    this.updateOptions();
    this.isAllLoaded = this.isAllLoaded || this.groups.findIndex(g => g.id === 'main') === -1;
    if (update) {
      await this.showMarketsByGroup(this.currentGroup || this.groups.find(g => g.id === 'main') || this.groups[0], first, first, false);
    }
    this.update();
  }

  private updateGroups(event: Event): void {
    this.groups = [
      ...new SortedSet((a: EventMarketGroup, b: EventMarketGroup) => b.priority - a.priority)
        .add(...this.groups)
        .add(...(event.availableMarketGroups || []))
        .values(),
    ];
  }

  private updateOptions(): void {
    this.segments = [
      {
        label: '',
        img: '/assets/images/sports/event.svg',
        ariaLabel: 'Event',
        value: 'EVENT',
      },
    ];

    if (this.event.video && this.event.live && !isEventEnded(this.event)) {
      this.segments.push({
        label: '',
        img: '/assets/images/sports/video.svg',
        ariaLabel: 'Video',
        value: 'VIDEO',
      });
    }

    if (this.event.tracker) {
      this.segments.push({
        label: '',
        img: '/assets/images/sports/tracker.svg',
        ariaLabel: 'Tracker',
        value: 'TRACKER',
      });
      this.segments.push({
        label: '',
        img: '/assets/images/sports/statistics.svg',
        ariaLabel: 'Statistics',
        value: 'STATISTICS',
      });
    }

    this.segment = this.segments.find(s => s.value === this.segment?.value) || this.segments[0];
  }

  private async updateMarkets(markets: EventMarket[], first: boolean): Promise<Map<string, EventMarket>> {
    return new Promise((resolve): void => {
      /* istanbul ignore else */
      if (typeof Worker !== 'undefined' && !first) {
        const worker = new Worker(new URL('./../../workers/update-markets.worker', import.meta.url));
        worker.onmessage = (event: MessageEvent) => {
          worker.terminate();
          resolve(event.data.allMarkets);
        };
        worker.postMessage({ allMarkets: this.allMarkets, markets });
      } else {
        resolve(mergeMarkets(this.allMarkets, markets));
      }
    });
  }

  private reduceMarketsByCurrentGroup(clean: boolean, first: boolean = false): Promise<Map<string, {
    markets: EventMarket[],
    disabled: boolean,
    expanded: boolean
  }>> {
    return new Promise((resolve): void => {
      /* istanbul ignore else */
      if (typeof Worker !== 'undefined' && !first) {
        const worker = new Worker(new URL('./../../workers/reduce-markets.worker', import.meta.url));
        worker.onmessage = (event: MessageEvent) => {
          worker.terminate();
          resolve(event.data.allMarkets);
        };
        worker.postMessage({
          allMarkets: this.allMarkets,
          groupId: this.currentGroup.id,
          season: !!this.event.season,
          clean,
          state: this.state,
        });
      } else {
        resolve(reduceMarkets(this.allMarkets, this.currentGroup.id, !!this.event.season, this.state, clean));
      }
    });
  }

  private updateAuthState(isAuthorized: boolean): void {
    this.isAuthorized = isAuthorized;
    this.changeDetectorRef.markForCheck();
  }

}
